export default {
    scrm: [
        {
            text: "一、系统首页",
            path: "/home/scrm/scrm1/1#系统首页",
            children: [{
                text: "1.数据统计",
                path: "/home/scrm/scrm1/1#数据统计",
            }, {
                text: "2.事件提醒",
                path: "/home/scrm/scrm1/1#事件提醒",
            }]
        },
        {
            text: "二、引流获客",
            path: "/home/scrm/scrm2/1#引流获客",
            children: [{
                text: "1.渠道活码",
                path: "/home/scrm/scrm2/1#渠道活码",
                children: [{
                    text: "1.1活码分组",
                    path: "/home/scrm/scrm2/1#活码分组",
                }, {
                    text: "1.2活码列表",
                    path: "/home/scrm/scrm2/1#活码列表",
                },
                {
                    text: "1.3活码编辑",
                    path: "/home/scrm/scrm2/1#活码编辑",
                }]
            }, {
                text: "2.搜索添加",
                path: "/home/scrm/scrm2/1#搜索添加",
            }]
        },
        {
            text: "三、客户转化",
            path: "/home/scrm/scrm3/1#客户转化",
            children: [{
                text: "1.客户群发",
                path: "/home/scrm/scrm3/1#客户群发",
                children: [{
                    text: "1.1群发列表",
                    path: "/home/scrm/scrm3/1#群发列表",
                }, {
                    text: "1.2新建群发",
                    path: "/home/scrm/scrm3/1#新建群发",
                },
                {
                    text: "1.3群发详情",
                    path: "/home/scrm/scrm3/1#群发详情",
                }]
            }, {
                text: "2.客户阶段",
                path: "/home/scrm/scrm3/2#客户阶段",
                children: [{
                    text: "2.1阶段列表",
                    path: "/home/scrm/scrm3/2#阶段列表",
                }, {
                    text: "2.2阶段设置",
                    path: "/home/scrm/scrm3/2#阶段设置",
                },
                {
                    text: "2.3阶段编辑",
                    path: "/home/scrm/scrm3/2#阶段编辑",
                }]
            }, {
                text: "3.好友欢迎语",
                path: "/home/scrm/scrm3/3#好友欢迎语",
                children: [{
                    text: "3.1欢迎语列表",
                    path: "/home/scrm/scrm3/3#欢迎语列表",
                }, {
                    text: "3.2欢迎语编辑",
                    path: "/home/scrm/scrm3/3#欢迎语编辑",
                }]
            }, {
                text: "4.企业话术库",
                path: "/home/scrm/scrm3/4#企业话术库",
                children: [{
                    text: "4.1话术库分组",
                    path: "/home/scrm/scrm3/4#话术库分组",
                }, {
                    text: "4.2话术库列表",
                    path: "/home/scrm/scrm3/4#话术库列表",
                }, {
                    text: "4.3话术库编辑",
                    path: "/home/scrm/scrm3/4#话术库编辑",
                }]
            }, {
                text: "5.素材库",
                path: "/home/scrm/scrm3/5#素材库",
                children: [{
                    text: "5.1素材分组",
                    path: "/home/scrm/scrm3/5#素材分组",
                }, {
                    text: "5.2素材列表",
                    path: "/home/scrm/scrm3/5#素材列表",
                }, {
                    text: "5.3素材编辑",
                    path: "/home/scrm/scrm3/5#素材编辑",
                }]
            }]
        },
        {
            text: "四、客户管理",
            path: "/home/scrm/scrm4/1#客户管理",
            children: [{
                text: "1.客户列表",
                path: "/home/scrm/scrm4/1#客户列表",
                children: [{
                    text: "1.1客户数据",
                    path: "/home/scrm/scrm4/1#客户数据",
                }, {
                    text: "1.2客户详情",
                    path: "/home/scrm/scrm4/1#客户详情",
                }]
            }, {
                text: "2.客户资料卡",
                path: "/home/scrm/scrm4/2#客户资料卡",
                children: [{
                    text: "2.1基本信息",
                    path: "/home/scrm/scrm4/2#基本信息",
                }, {
                    text: "2.2自定义字段",
                    path: "/home/scrm/scrm4/2#自定义字段",
                }]
            },
            {
                text: "3.客户标签",
                path: "/home/scrm/scrm4/3#客户标签",
                children: [{
                    text: "3.1标签列表",
                    path: "/home/scrm/scrm4/3#标签列表",
                }, {
                    text: "3.2标签编辑",
                    path: "/home/scrm/scrm4/3#标签编辑",
                }]
            },
            {
                text: "4.客户统计",
                path: "/home/scrm/scrm4/4#客户统计",
                children: [{
                    text: "4.1客户数据",
                    path: "/home/scrm/scrm4/4#客户数据",
                }, {
                    text: "4.2表格展示",
                    path: "/home/scrm/scrm4/4#表格展示",
                }]
            }]
        },
        {
            text: "五、客户群运营",
            path: "/home/scrm/scrm5/1#客户群运营",
            children: [{
                text: "1.客户群列表",
                path: "/home/scrm/scrm5/1#客户群列表",
                children: [{
                    text: "1.1客户群数据",
                    path: "/home/scrm/scrm5/1#客户群数据",
                }, {
                    text: "1.2客户群详情",
                    path: "/home/scrm/scrm5/1#客户群详情",
                }]
            }, {
                text: "2.客户群标签",
                path: "/home/scrm/scrm5/2#客户群标签",
                children: [{
                    text: "2.1群标签列表",
                    path: "/home/scrm/scrm5/2#群标签列表",
                }, {
                    text: "2.2群标签编辑",
                    path: "/home/scrm/scrm5/2#群标签编辑",
                }]
            }, {
                text: "3.客户群群发",
                path: "/home/scrm/scrm5/3#客户群群发",
                children: [{
                    text: "3.1群发列表",
                    path: "/home/scrm/scrm5/3#群发列表",
                }, {
                    text: "3.2群发编辑",
                    path: "/home/scrm/scrm5/3#群发编辑",
                }]
            }]
        },
        {
            text: "六、聊天侧边栏",
            path: "/home/scrm/scrm6/1#聊天侧边栏",
            children: [{
                text: "1.用户画像",
                path: "/home/scrm/scrm6/1#用户画像",
            }, {
                text: "2.聊天增强",
                path: "/home/scrm/scrm6/1#聊天增强",
            }]
        },
        {
            text: "七、成员管理",
            path: "/home/scrm/scrm7/1#成员管理",
        },
        {
            text: "八、角色管理",
            path: "/home/scrm/scrm8/1#角色管理",
            children: [{
                text: "1.角色列表",
                path: "/home/scrm/scrm8/1#角色列表",
            }, {
                text: "2.角色权限",
                path: "/home/scrm/scrm8/1#角色权限",
            }]
        }
    ]
}