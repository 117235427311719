export default {
    finance: [{
        text: "一、财务流水",
        path: "/home/finance/finance1/1#财务流水",
        children: [{
            text: "1.财务流水",
            path: "/home/finance/finance1/1#财务流水",
            children: [{
                text: "1.1财务流水查询",
                path: "/home/finance/finance1/1#财务流水查询",
            }, {
                text: "1.2老生缴费查询",
                path: "/home/finance/finance1/1#老生缴费查询",
            }, {
                text: "1.3批量确认流水",
                path: "/home/finance/finance1/1#批量确认流水",
            }]
        },
        {
            text: "2.在线支付流水",
            path: "/home/finance/finance1/1#在线支付流水",
        },
        {
            text: "3.管理费查询",
            path: "/home/finance/finance1/1#添加学员",
        },
        {
            text: "4.添加管理费",
            path: "/home/finance/finance1/1#添加管理费",
        },
        {
            text: "5.学生转账记录",
            path: "/home/finance/finance1/1#学生转账记录",
        },
        {
            text: "6.财务流水回收站",
            path: "/home/finance/finance1/1#财务流水回收站",
        },]
    }, {
        text: "二、办理缴费",
        path: "/home/finance/finance2/1#办理缴费",
        children: [
            {
                text: "1.新生缴费",
                path: "/home/finance/finance2/1#新生缴费",
            },
            {
                text: "2.续报缴费",
                path: "/home/finance/finance2/1#续报缴费",
            },
            {
                text: "3.分期付款",
                path: "/home/finance/finance2/1#分期付款",
            },
            {
                text: "4.新增分期付款",
                path: "/home/finance/finance2/1#新增分期付款",
            },
            {
                text: "5.定金缴费",
                path: "/home/finance/finance2/1#定金缴费",
            },
            {
                text: "6.补缴",
                path: "/home/finance/finance2/1#补缴",
            },
            {
                text: "7.合作点缴费",
                path: "/home/finance/finance2/1#合作点缴费",
                children: [{
                    text: "7.1合作点缴费查询",
                    path: "/home/finance/finance2/1#合作点缴费查询",
                }, {
                    text: "7.2添加合作点",
                    path: "/home/finance/finance2/1#添加合作点",
                }]
            },
            {
                text: "8.电子合同",
                path: "/home/finance/finance2/1#电子合同",
            },
        ]
    }, {
        text: "三、办理退费",
        path: "/home/finance/finance3/1#办理退费",
        children: [
            {
                text: "1.提交退费申请单",
                path: "/home/finance/finance3/1#提交退费申请单",
            },
            {
                text: "2.退费审批",
                path: "/home/finance/finance3/1#退费审批",
            },
            {
                text: "3.退费申请查询",
                path: "/home/finance/finance3/1#退费申请查询",
            },
            {
                text: "4.在线报考退费",
                path: "/home/finance/finance3/1#在线报考退费",
            }
        ]
    },
    {
        text: "四、其它",
        path: "/home/finance/finance4/1#其它",
        children: [
            {
                text: "1.添加学生",
                path: "/home/finance/finance4/1#添加学生",
            },
            {
                text: "2.积分兑换",
                path: "/home/finance/finance4/1#积分兑换",
            },
            {
                text: "3.导入财务数据",
                path: "/home/finance/finance4/1#导入财务数据",
            }
        ]
    },
    {
        text: "五、数据分析",
        path: "/home/finance/finance5/1#数据分析",
        children: [
            {
                text: "1.业绩统计",
                path: "/home/finance/finance5/1#业绩统计",
            },
            {
                text: "2.财务统计",
                path: "/home/finance/finance5/1#财务统计",
            }
        ]
    }
    ]
}