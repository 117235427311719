<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <header class="app-header">
      <img
        v-if="$route.path != '/'"
        class="app-icon-menu"
        src="@/assets/img/icon-menu.png"
        @click="drawerSwitch"
      />
      <img class="app-img" src="@/assets/img/zxtlogo.png" />
      <el-tag effect="dark" size="mini" @click="toIndex" style="cursor: pointer"
        >文档中心</el-tag
      >
    </header>
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Index",
  data() {
    return {};
  },
  mounted() {},
  watch: {
    $route(to) {
      if (to.fullPath.indexOf("#") != -1) {
        this.$utils.pointTop(to.fullPath.split("#")[1]);
      }
    },
  },
  methods: {
    ...mapMutations(["drawerSwitch"]),
    toIndex() {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>


<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background: #f5f8fa;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.app-header {
  display: flex;
  height: 60px;
  //  border-bottom:1px solid #eaecef ;
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  padding: 0 1.5rem;
  background: white;
}
.app-img {
  height: 40px;
  margin-right: 10px;
}
.app-tag {
  background: #409eff;
  color: white;
}
.app-icon-menu {
  display: none;
  width: 23px;
  height: 23px;
  margin-right: 10px;
}
.el-drawer {
  width: 65% !important;
}
.readme-article {
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 2rem;
  padding-bottom: 2rem;
}
.readme-article img{
  max-width: 100%;
}
h1{
  font-size: 2.2rem;
  margin: 0;
  line-height: 5rem;
}
h2{
  margin: 0;
  line-height: 4rem;
}
ol, p, ul {
    line-height: 3rem;;
    margin: 0;
}
@media screen and (max-width: 720px) {
  .app-icon-menu {
    display: block;
  }
}
</style>
