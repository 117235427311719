export default {
    student: [{
        text: "系统搭建",
        path: "/home/student/student0/0#系统搭建",
        children: [{
            text: "系统使用前配置",
            path: "/home/student/student0/0#系统使用前配置",
        }, {
            text: "1.专业院校配置",
            path: "/home/student/student0/1#专业院校配置",
            children: [{
                text: "1.1层次设置",
                path: "/home/student/student0/1#层次设置",
            },
            {
                text: "1.2院校设置",
                path: "/home/student/student0/1#院校设置",
            },
            {
                text: "1.3自考专业设置",
                path: "/home/student/student0/1#自考专业设置",
            },
            {
                text: "1.4科目设置",
                path: "/home/student/student0/1#科目设置",
            },
            {
                text: "1.5专业科目设置",
                path: "/home/student/student0/1#专业科目设置",
            },
            {
                text: "1.6班型设置",
                path: "/home/student/student0/1#班型设置",
            }]
        }, {
            text: "2.教务配置",
            path: "/home/student/student0/2#教务配置",
            children: [{
                text: "2.1学员期数",
                path: "/home/student/student0/2#学员期数",
            },
            {
                text: "2.2报考期数",
                path: "/home/student/student0/2#报考期数",
            },
            {
                text: "2.3替考计划",
                path: "/home/student/student0/2#替考计划",
            },
            {
                text: "2.4考区",
                path: "/home/student/student0/2#考区",
            },
            {
                text: "2.5学员标签",
                path: "/home/student/student0/2#学员标签",
            }]
        }, {
            text: "3.财务配置",
            path: "/home/student/student0/3#财务配置",
            children: [{
                text: "3.1付款方式",
                path: "/home/student/student0/3#付款方式",
            },
            {
                text: "3.2价格设置",
                path: "/home/student/student0/3#价格设置",
            },
            {
                text: "3.3折扣设置",
                path: "/home/student/student0/3#折扣设置",
            },
            {
                text: "3.4收费项目",
                path: "/home/student/student0/3#收费项目",
            },
            {
                text: "3.5支付渠道",
                path: "/home/student/student0/3#支付渠道",
            },
            {
                text: "3.6在线支付设置",
                path: "/home/student/student0/3#在线支付设置",
            },
            {
                text: "3.7积分规则",
                path: "/home/student/student0/3#积分规则",
            },
            {
                text: "3.8定金设置",
                path: "/home/student/student0/3#定金设置",
            }]
        }, {
            text: "4.招生配置",
            path: "/home/student/student0/4#招生配置",
            children: [{
                text: "4.1线索来源渠道",
                path: "/home/student/student0/4#线索来源渠道",
            },
            {
                text: "4.2线索意向状态",
                path: "/home/student/student0/4#线索意向状态",
            },
            {
                text: "4.3流失原因",
                path: "/home/student/student0/4#流失原因",
            },
            {
                text: "4.4线索登记方式",
                path: "/home/student/student0/4#线索登记方式",
            },
            {
                text: "4.5线索层次/期数",
                path: "/home/student/student0/4#线索层次-期数",
            },
            {
                text: "4.6线索名片来源",
                path: "/home/student/student0/4#线索名片来源",
            },
            {
                text: "4.7线索名片商机等级",
                path: "/home/student/student0/4#线索名片商机等级",
            },
            ]
        }, {
            text: "5.其他配置",
            path: "/home/student/student0/5#其他配置",
            children: [{
                text: "5.1校区设置",
                path: "/home/student/student0/5#校区设置",
            },
            {
                text: "5.2分公司设置",
                path: "/home/student/student0/5#分公司设置",
            },
            ]
        }]
    }, {
        text: "一、线索管理",
        path: "/home/student/student1/1#线索管理",
        children: [{
            text: "1.招生线索管理",
            path: "/home/student/student1/1#招生线索管理",
            children: [
                {
                    text: "1.1线索列表",
                    path: "/home/student/student1/1#线索列表",
                },
                {
                    text: "1.2线索操作",
                    path: "/home/student/student1/1#线索操作",
                },
                {
                    text: "1.3线索回访",
                    path: "/home/student/student1/1#线索回访",
                }
            ]
        },
        {
            text: "2.添加招生线索",
            path: "/home/student/student1/1#添加招生线索",
        },
        {
            text: "3.线索池",
            path: "/home/student/student1/1#线索池",
        },
        {
            text: "4.线索公海",
            path: "/home/student/student1/1#线索公海",
            children: [
                {
                    text: "4.1公海概述",
                    path: "/home/student/student1/1#公海概述",
                },
                {
                    text: "4.2公海规则调配",
                    path: "/home/student/student1/1#公海规则调配",
                },
            ]
        },
        {
            text: "5.活跃线索",
            path: "/home/student/student1/1#活跃线索",
        },
        {
            text: "6.已报名线索",
            path: "/home/student/student1/1#已报名线索",
        },
        {
            text: "7.二访线索",
            path: "/home/student/student1/1#二访线索",
        },
        {
            text: "8.静默线索",
            path: "/home/student/student1/1#静默线索",
        }]

    }, {
        text: "二、回访跟进",
        path: "/home/student/student2/1#回访跟进",
        children: [
            {
                text: "1.预约池",
                path: "/home/student/student2/1#预约池",
            },
            {
                text: "2.回访跟踪管理",
                path: "/home/student/student2/1#回访跟踪管理",
            },
            {
                text: "3.云呼叫坐席",
                path: "/home/student/student2/1#云呼叫坐席",
            },
            {
                text: "4.电话记录管理",
                path: "/home/student/student2/1#电话记录管理",
            },
            {
                text: "5.主管首页",
                path: "/home/student/student2/1#主管首页",
            },
        ],
    }, {
        text: "三、缴费申请",
        path: "/home/student/student3/1#缴费申请",
        children: [
            {
                text: "1.转正",
                path: "/home/student/student3/1#转正",
            },
            {
                text: "2.查看转正申请",
                path: "/home/student/student3/1#查看转正申请",
            },
            {
                text: "3.续报",
                path: "/home/student/student3/1#续报",
            },
            {
                text: "4.查看续报申请",
                path: "/home/student/student3/1#查看续报申请",
            },
            {
                text: "5.定金",
                path: "/home/student/student3/1#定金",
            },
            {
                text: "6.查看定金申请",
                path: "/home/student/student3/1#查看定金申请",
            },
        ]
    },
    {
        text: "四、其他",
        path: "/home/student/student4/1#其他",
        children: [
            {
                text: "1.线索名片",
                path: "/home/student/student4/1#线索名片",
            },
            {
                text: "2.验证电话号码",
                path: "/home/student/student4/1#验证电话号码",
            },
            {
                text: "3.统计报表",
                path: "/home/student/student4/1#统计报表",
            },
            {
                text: "4.业绩查看",
                path: "/home/student/student4/1#业绩查看",
            },
            {
                text: "5.导入数据",
                path: "/home/student/student4/1#导入数据",
            }
        ]
    },
    {
        text: "五、线索调配管理",
        path: "/home/student/student5/1#线索调配管理",
        children: [
            {
                text: "1.客服分配规则",
                path: "/home/student/student5/1#客服分配规则",
            },
            {
                text: "2.线索回收规则",
                path: "/home/student/student5/1#线索回收规则",
            },
            {
                text: "3.公海领取规则",
                path: "/home/student/student5/1#公海领取规则",
            },
            {
                text: "4.线索锁定规则",
                path: "/home/student/student5/1#线索锁定规则",
            },
            {
                text: "5.线索池领取规则",
                path: "/home/student/student5/1#线索池领取规则",
            }
        ]
    }
    ]
}