const education = [
    {
        path: '/home/education/education1/1',
        name: 'Education1-1',
        component: () => import('../views/education/education1/1.vue')
    },
    {
        path: '/home/education/education2/1',
        name: 'Education2-1',
        component: () => import('../views/education/education2/1.vue')
    },
    {
        path: '/home/education/education3/1',
        name: 'Education3-1',
        component: () => import('../views/education/education3/1.vue')
    },
    {
        path: '/home/education/education4/1',
        name: 'Education4-1',
        component: () => import('../views/education/education4/1.vue')
    },
    {
        path: '/home/education/education5/1',
        name: 'Education5-1',
        component: () => import('../views/education/education5/1.vue')
    },
]

export default education