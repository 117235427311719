import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer:false,
    type:'student'
  },
  mutations: {
    drawerSwitch(state){
      state.drawer=!state.drawer
    },
    typeSwitch(state,data){
      state.type=data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
