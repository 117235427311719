const student = [
    {
        path: '/home/student/student0/0',
        name: 'Student0-0',
        component: () => import('../views/student/student0/0.vue')
    },
    {
        path: '/home/student/student0/1',
        name: 'Student0-1',
        component: () => import('../views/student/student0/1.vue')
    },
    {
        path: '/home/student/student0/2',
        name: 'Student0-2',
        component: () => import('../views/student/student0/2.vue')
    },
    {
        path: '/home/student/student0/3',
        name: 'Student0-3',
        component: () => import('../views/student/student0/3.vue')
    },
    {
        path: '/home/student/student0/4',
        name: 'Student0-4',
        component: () => import('../views/student/student0/4.vue')
    },
    {
        path: '/home/student/student0/5',
        name: 'Student0-5',
        component: () => import('../views/student/student0/5.vue')
    },
    {
        path: '/home/student/student1/1',
        name: 'Student1-1',
        component: () => import('../views/student/student1/1.vue')
    },
    {
        path: '/home/student/student2/1',
        name: 'Student2-1',
        component: () => import('../views/student/student2/1.vue')
    },
    {
        path: '/home/student/student3/1',
        name: 'Student3-1',
        component: () => import('../views/student/student3/1.vue')
    },
    {
        path: '/home/student/student4/1',
        name: 'Student4-1',
        component: () => import('../views/student/student4/1.vue')
    },
    {
        path: '/home/student/student5/1',
        name: 'Student5-1',
        component: () => import('../views/student/student5/1.vue')
    },
]

export default student