const call = [
    {
        path: '/home/call/call1/1',
        name: 'Call1-1',
        component: () => import('../views/call/call1/1.vue')
    },
    {
        path: '/home/call/call2/1',
        name: 'Call2-1',
        component: () => import('../views/call/call2/1.vue')
    },
    {
        path: '/home/call/call3/1',
        name: 'Call3-1',
        component: () => import('../views/call/call3/1.vue')
    },
    {
        path: '/home/call/call4/1',
        name: 'Call4-1',
        component: () => import('../views/call/call4/1.vue')
    },
    {
        path: '/home/call/call5/1',
        name: 'Call5-1',
        component: () => import('../views/call/call5/1.vue')
    },
    {
        path: '/home/call/call6/1',
        name: 'Call6-1',
        component: () => import('../views/call/call6/1.vue')
    },
    {
        path: '/home/call/call7/1',
        name: 'Call7-1',
        component: () => import('../views/call/call7/1.vue')
    },
]

export default call