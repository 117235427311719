export default [
    {
        path: '/home/card/card0/1',
        name: 'Card0-1',
        component: () => import('../views/card/card0/1.vue')
    }, {
        path: '/home/card/card1/1',
        name: 'Card1-1',
        component: () => import('../views/card/card1/1.vue')
    }, {
        path: '/home/card/card2/1',
        name: 'Card2-1',
        component: () => import('../views/card/card2/1.vue')
    }, {
        path: '/home/card/card3/1',
        name: 'Card3-1',
        component: () => import('../views/card/card3/1.vue')
    }, {
        path: '/home/card/card4/1',
        name: 'Card4-1',
        component: () => import('../views/card/card4/1.vue')
    }, {
        path: '/home/card/card5/1',
        name: 'Card5-1',
        component: () => import('../views/card/card5/1.vue')
    }, {
        path: '/home/card/card6/1',
        name: 'Card6-1',
        component: () => import('../views/card/card6/1.vue')
    }, {
        path: '/home/card/card7/1',
        name: 'Card7-1',
        component: () => import('../views/card/card7/1.vue')
    }

]