export default {
    card: [{
        text: "一、功能简介",
        path: "/home/card/card0/1#功能简介",
        children: [{
            text: "1.引导页",
            path: "/home/card/card0/1#引导页",
        },
        {
            text: "2.名片",
            path: "/home/card/card0/1#名片",
        },
        {
            text: "3.产品",
            path: "/home/card/card0/1#产品",
        },
        {
            text: "4.动态",
            path: "/home/card/card0/1#动态",
        },
        {
            text: "5.试用",
            path: "/home/card/card0/1#试用",
        }]
    }, {
        text: "二、后台使用",
        path: "/home/card/card1/1#班级管理",
        children: [
            {
                text: "1.首页",
                path: "/home/card/card1/1#首页",
                children: [{
                    text: "1.1欢迎界面",
                    path: "/home/card/card1/1#欢迎界面",
                }]
            },
            {
                text: "2.名片",
                path: "/home/card/card2/1#名片",
                children: [{
                    text: "2.1名片列表",
                    path: "/home/card/card2/1#名片列表",
                }, {
                    text: "2.2通用设置",
                    path: "/home/card/card2/1#通用设置",
                }, {
                    text: "2.3表单模板",
                    path: "/home/card/card2/1#表单模板",
                }]
            },
            {
                text: "3.产品",
                path: "/home/card/card3/1#产品",
                children: [{
                    text: "3.1产品列表",
                    path: "/home/card/card3/1#产品列表",
                }, {
                    text: "3.2产品分类",
                    path: "/home/card/card3/1#产品分类",
                }]
            },
            {
                text: "4.用户",
                path: "/home/card/card4/1#用户",
                children: [{
                    text: "4.1用户列表",
                    path: "/home/card/card4/1#用户列表",
                }, {
                    text: "4.2会员等级",
                    path: "/home/card/card4/1#会员等级",
                    children: [{
                        text: "4.2.1等级管理",
                        path: "/home/card/card4/1#等级管理",
                    }]
                },
                {
                    text: "4.3余额记录",
                    path: "/home/card/card4/1#余额记录",
                    children: [{
                        text: "4.3.1充值记录",
                        path: "/home/card/card4/1#充值记录",
                    },
                    {
                        text: "4.3.2余额明细",
                        path: "/home/card/card4/1#余额明细",
                    },
                    {
                        text: "4.3.3提现列表",
                        path: "/home/card/card4/1#提现列表",
                    }]
                },
                {
                    text: "4.4客户需求",
                    path: "/home/card/card4/1#客户需求",
                }]
            },
            {
                text: "5.网站",
                path: "/home/card/card5/1#网站",
                children: [{
                    text: "5.1文章管理",
                    path: "/home/card/card5/1#文章管理",
                    children: [{
                        text: "5.1.1文章列表",
                        path: "/home/card/card5/1#文章列表",
                    },
                    {
                        text: "5.1.2文章分类",
                        path: "/home/card/card5/1#文章分类",
                    }]
                }, {
                    text: "5.2文件库管理",
                    path: "/home/card/card5/1#文件库管理",
                    children: [{
                        text: "5.2.1文件分组",
                        path: "/home/card/card5/1#文件分组",
                    },
                    {
                        text: "5.2.2文件列表",
                        path: "/home/card/card5/1#文件列表",
                    },
                    {
                        text: "5.2.3回收站",
                        path: "/home/card/card5/1#回收站",
                    }]
                }]
            },
            {
                text: "6.管理员",
                path: "/home/card/card6/1#管理员",
                children: [{
                    text: "6.1管理员列表",
                    path: "/home/card/card6/1#管理员列表",
                }, {
                    text: "6.2角色管理",
                    path: "/home/card/card6/1#角色管理",
                }]
            },
            {
                text: "7.设置",
                path: "/home/card/card7/1#设置",
                children: [{
                    text: "7.1基本设置",
                    path: "/home/card/card7/1#基本设置",
                }, {
                    text: "7.2短信通知",
                    path: "/home/card/card7/1#短信通知",
                }, {
                    text: "7.3上传设置",
                    path: "/home/card/card7/1#上传设置",
                }, {
                    text: "7.4其他",
                    path: "/home/card/card7/1#其他",
                    children: [{
                        text: "7.4.1清理缓存",
                        path: "/home/card/card7/1#清理缓存",
                    }]
                },
                {
                    text: "7.5小程序设置",
                    path: "/home/card/card7/1#小程序设置",
                }]
            }
        ]
    }]
}