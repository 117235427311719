export default {
    education: [{
        text: "一、学员管理",
        path: "/home/education/education1/1#学员管理",
        children: [{
            text: "1.学员资料查询",
            path: "/home/education/education1/1#学员资料查询",
            children: [{
                text: "1.1学员信息列表",
                path: "/home/education/education1/1#学员信息列表",
            }, {
                text: "1.2学员信息详情",
                path: "/home/education/education1/1#学员信息详情",
            }]
        },
        {
            text: "2.学员树状查询",
            path: "/home/education/education1/1#学员树状查询",
        },
        {
            text: "3.添加学员",
            path: "/home/education/education1/1#添加学员",
        }]
    }, {
        text: "二、班级管理",
        path: "/home/education/education2/1#班级管理",
        children: [
            {
                text: "1.班级列表",
                path: "/home/education/education2/1#班级列表",
                children: [{
                    text: "1.1班级信息列表",
                    path: "/home/education/education2/1#班级信息列表",
                }, {
                    text: "1.2班级详情",
                    path: "/home/education/education2/1#班级详情",
                }, {
                    text: "1.3添加班级",
                    path: "/home/education/education2/1#添加班级",
                }, {
                    text: "1.4转班主任",
                    path: "/home/education/education2/1#转班主任",
                }]
            },
            {
                text: "2.班主任",
                path: "/home/education/education2/1#班主任",
            },
            {
                text: "3.班级学员管理",
                path: "/home/education/education2/1#班级学员管理",
            },
        ]
    }, {
        text: "三、学员关怀",
        path: "/home/education/education3/1#学员关怀",
        children: [
            {
                text: "1.近期需回访",
                path: "/home/education/education3/1#近期需回访",
            },
            {
                text: "2.近期已回访",
                path: "/home/education/education3/1#近期已回访",
            },
            {
                text: "3.回访记录",
                path: "/home/education/education3/1#回访记录",
            }
        ]
    },
    {
        text: "四、报考&成绩",
        path: "/home/education/education4/1#报考&成绩",
        children: [
            {
                text: "1.成绩查询",
                path: "/home/education/education4/1#成绩查询",
            },
            {
                text: "2.添加成绩",
                path: "/home/education/education4/1#添加成绩",
            },
            {
                text: "3.学员报考",
                path: "/home/education/education4/1#学员报考",
                children: [
                    {
                        text: "3.1学员报考列表",
                        path: "/home/education/education4/1#学员报考列表",
                    },
                    {
                        text: "3.2学员报考科目配置",
                        path: "/home/education/education4/1#学员报考科目配置",
                    },
                ]
            },
            {
                text: "4.报考记录查询",
                path: "/home/education/education4/1#报考记录查询",
            },
            {
                text: "5.报考费流水",
                path: "/home/education/education4/1#报考费流水",
            },
            {
                text: "6.报考撤销审批",
                path: "/home/education/education4/1#报考撤销审批",
            },
            {
                text: "7.报考费流水回收站",
                path: "/home/education/education4/1#报考费流水回收站",
            },
        ]
    },
    {
        text: "五、其他",
        path: "/home/education/education5/1#其他",
        children: [
            {
                text: "1.统计报表",
                path: "/home/education/education5/1#统计报表",
            },
            {
                text: "2.导入数据",
                path: "/home/education/education5/1#导入数据",
            },
            {
                text: "3.在线课程",
                path: "/home/education/education5/1#在线课程",
                children: [{
                    text: "3.1录播课",
                    path: "/home/education/education5/1#录播课",
                }, {
                    text: "3.2添加录播课",
                    path: "/home/education/education5/1#添加录播课",
                }, {
                    text: "3.3直播课",
                    path: "/home/education/education5/1#直播课",
                }, {
                    text: "3.4添加直播课",
                    path: "/home/education/education5/1#添加直播课",
                }]
            }
        ]
    }
    ]
}