import student from './student'
import education from './education'
import card from './card'
import scrm from './scrm'
import finance from './finance'
import call from './call'
export default {
    ...student, // 招生菜单
    ...education, // 教务菜单
    ...card, // 裂变名片小程序
    ...scrm, // 助校通scrm
    ...finance, // 财务
    ...call // 飞牛云外呼
}