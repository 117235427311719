export default [
    {
        path: '/home/scrm/scrm1/1',
        name: 'scrm1-1',
        component: () => import('../views/scrm/scrm1/1.vue')
    }, {
        path: '/home/scrm/scrm2/1',
        name: 'scrm2-1',
        component: () => import('../views/scrm/scrm2/1.vue')
    }, {
        path: '/home/scrm/scrm3/1',
        name: 'scrm3-1',
        component: () => import('../views/scrm/scrm3/1.vue')
    }, {
        path: '/home/scrm/scrm3/2',
        name: 'scrm3-2',
        component: () => import('../views/scrm/scrm3/2.vue')
    }, {
        path: '/home/scrm/scrm3/3',
        name: 'scrm3-3',
        component: () => import('../views/scrm/scrm3/3.vue')
    }, {
        path: '/home/scrm/scrm3/4',
        name: 'scrm3-4',
        component: () => import('../views/scrm/scrm3/4.vue')
    }, {
        path: '/home/scrm/scrm3/5',
        name: 'scrm3-5',
        component: () => import('../views/scrm/scrm3/5.vue')
    }, {
        path: '/home/scrm/scrm4/1',
        name: 'scrm4-1',
        component: () => import('../views/scrm/scrm4/1.vue')
    }, {
        path: '/home/scrm/scrm4/2',
        name: 'scrm4-2',
        component: () => import('../views/scrm/scrm4/2.vue')
    }, {
        path: '/home/scrm/scrm4/3',
        name: 'scrm4-3',
        component: () => import('../views/scrm/scrm4/3.vue')
    }, {
        path: '/home/scrm/scrm4/4',
        name: 'scrm4-4',
        component: () => import('../views/scrm/scrm4/4.vue')
    }, {
        path: '/home/scrm/scrm5/1',
        name: 'scrm5-1',
        component: () => import('../views/scrm/scrm5/1.vue')
    }, {
        path: '/home/scrm/scrm5/2',
        name: 'scrm5-2',
        component: () => import('../views/scrm/scrm5/2.vue')
    }, {
        path: '/home/scrm/scrm5/3',
        name: 'scrm5-3',
        component: () => import('../views/scrm/scrm5/3.vue')
    }, {
        path: '/home/scrm/scrm6/1',
        name: 'scrm6-1',
        component: () => import('../views/scrm/scrm6/1.vue')
    }, {
        path: '/home/scrm/scrm7/1',
        name: 'scrm7-1',
        component: () => import('../views/scrm/scrm7/1.vue')
    }, {
        path: '/home/scrm/scrm8/1',
        name: 'scrm8-1',
        component: () => import('../views/scrm/scrm8/1.vue')
    }
]