export default {
    call: [{
        text: "一、线索",
        path: "/home/call/call1/1#线索",
        children: [{
            text: "1.线索管理",
            path: "/home/call/call1/1#线索管理",
        },
        {
            text: "2.添加线索",
            path: "/home/call/call1/1#添加线索",
        },
        {
            text: "3.组内流转",
            path: "/home/call/call1/1#组内流转",
        },
        {
            text: "4.线索公海",
            path: "/home/call/call1/1#线索公海",
        },
        {
            text: "5.活跃线索",
            path: "/home/call/call1/1#活跃线索",
        },
        {
            text: "6.二访线索",
            path: "/home/call/call1/1#二访线索",
        }, {
            text: "7.静默线索",
            path: "/home/call/call1/1#静默线索",
        }, {
            text: "8.导入数据",
            path: "/home/call/call1/1#导入数据",
        },]
    }, {
        text: "二、回访",
        path: "/home/call/call2/1#回访",
        children: [
            {
                text: "1.预约池",
                path: "/home/call/call2/1#预约池",
            },
            {
                text: "2.回访跟踪管理",
                path: "/home/call/call2/1#回访跟踪管理",
            },
            {
                text: "3.线索电话记录",
                path: "/home/call/call2/1#线索电话记录",
            }
        ]
    }, {
        text: "三、调配",
        path: "/home/call/call3/1#调配",
        children: [
            {
                text: "1.线索回收规则",
                path: "/home/call/call3/1#线索回收规则",
            },
            {
                text: "2.公海领取规则",
                path: "/home/call/call3/1#公海领取规则",
            },
            {
                text: "3.线索锁定规则",
                path: "/home/call/call3/1#线索锁定规则",
            },
            {
                text: "4.线索二访规则",
                path: "/home/call/call3/1#线索二访规则",
            },
            {
                text: "5.组内流转规则",
                path: "/home/call/call3/1#组内流转规则",
            },
            {
                text: "6.流转领取规则",
                path: "/home/call/call3/1#流转领取规则",
            },
        ]
    },
    {
        text: "四、用户",
        path: "/home/call/call4/1#用户",
        children: [
            {
                text: "1.用户列表",
                path: "/home/call/call4/1#用户列表",
            },
            {
                text: "2.添加用户",
                path: "/home/call/call4/1#添加用户",
            },
            {
                text: "3.用户组与权限",
                path: "/home/call/call4/1#用户组与权限",
            },
            {
                text: "4.业务人员分组设置",
                path: "/home/call/call4/1#业务人员分组设置",
            },
            {
                text: "5.业务人员等级",
                path: "/home/call/call4/1#业务人员等级",
            },
            {
                text: "6.业务专员",
                path: "/home/call/call4/1#业务专员",
            },
            {
                text: "7.我的资料",
                path: "/home/call/call4/1#我的资料",
            },
            {
                text: "8.修改密码",
                path: "/home/call/call4/1#修改密码",
            }
        ]
    },
    {
        text: "五、日常",
        path: "/home/call/call5/1#日常",
        children: [
            {
                text: "1.通知公告",
                path: "/home/call/call5/1#通知公告",
            },
            {
                text: "2.操作日志管理",
                path: "/home/call/call5/1#操作日志管理",
            },
            {
                text: "3.登陆日志管理",
                path: "/home/call/call5/1#登陆日志管理",
            },
            {
                text: "4.电话解密日志",
                path: "/home/call/call5/1#电话解密日志",
            },
            {
                text: "5.日志清除",
                path: "/home/call/call5/1#日志清除",
            },
        ]
    },
    {
        text: "六、报表",
        path: "/home/call/call6/1#报表",
        children: [
            {
                text: "1.线索统计",
                path: "/home/call/call6/1#线索统计",
            },
            {
                text: "2.按日期统计新增线索",
                path: "/home/call/call6/1#按日期统计新增线索",
            },
            {
                text: "3.线索来源统计",
                path: "/home/call/call6/1#线索来源统计",
            },
            {
                text: "4.登记方式统计",
                path: "/home/call/call6/1#登记方式统计",
            },
            {
                text: "5.按意向状态统计",
                path: "/home/call/call6/1#按意向状态统计",
            },
            {
                text: "6.流失原因统计",
                path: "/home/call/call6/1#流失原因统计",
            },
            {
                text: "7.无效原因统计",
                path: "/home/call/call6/1#无效原因统计",
            },
            {
                text: "8.客服信息统计",
                path: "/home/call/call6/1#客服信息统计",
            }
        ]
    },
    {
        text: "七、设置",
        path: "/home/call/call7/1#设置",
        children: [
            {
                text: "1.线索来源渠道",
                path: "/home/call/call7/1#线索来源渠道",
            },
            {
                text: "2.线索意向状态",
                path: "/home/call/call7/1#线索意向状态",
            },
            {
                text: "3.流失原因",
                path: "/home/call/call7/1#流失原因",
            },
            {
                text: "4.线索登记方式",
                path: "/home/call/call7/1#线索登记方式",
            }
        ]
    },
    ]
}