const finance = [
    {
        path: '/home/finance/finance1/1',
        name: 'finance1-1',
        component: () => import('../views/finance/finance1/1.vue')
    },
    {
        path: '/home/finance/finance2/1',
        name: 'finance2-1',
        component: () => import('../views/finance/finance2/1.vue')
    },
    {
        path: '/home/finance/finance3/1',
        name: 'finance3-1',
        component: () => import('../views/finance/finance3/1.vue')
    },
    {
        path: '/home/finance/finance4/1',
        name: 'finance4-1',
        component: () => import('../views/finance/finance4/1.vue')
    },
    {
        path: '/home/finance/finance5/1',
        name: 'finance5-1',
        component: () => import('../views/finance/finance5/1.vue')
    },
]

export default finance